import React from 'react'
import { useTranslate } from 'react-polyglot'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import { Box, Flex } from '@chatterbug/aaron'
import { PolyglotFn } from 'src/types'

import CountDownItem from './CountDownItem'
import { CountdownVariant, getCountdownColor } from '../utils'

export type Props = {
  endAt: Date | string
  onComplete: () => void
  countdownMessage: string
  countdownVariant: CountdownVariant
  showBorder?: boolean
}

const renderer = (
  props: CountdownRenderProps,
  t: PolyglotFn,
  countdownVariant: CountdownVariant
): JSX.Element => {
  const { days, hours, minutes, seconds } = props

  const items = [
    {
      label: t('banner_black_friday.count_down_days'),
      value: days,
    },
    { label: t('banner_black_friday.count_down_hours'), value: hours },
    { label: t('banner_black_friday.count_down_minutes'), value: minutes },
    { label: t('banner_black_friday.count_down_seconds'), value: seconds },
  ]

  return (
    <>
      {items.map((item, index) => (
        <CountDownItem
          key={`count-down-item-${index}`}
          index={index}
          label={item.label}
          value={item.value}
          countdownVariant={countdownVariant}
        />
      ))}
    </>
  )
}

const OfferCountDown: React.FC<Props> = ({
  endAt,
  onComplete,
  countdownMessage,
  countdownVariant,
  showBorder = true,
}) => {
  const t = useTranslate()

  return (
    <Box>
      <Flex
        minHeight={32}
        alignItems="center"
        justifyContent={{
          _: 'center',
          tablet: 'flex-end',
        }}
        pl={{ _: 0, tablet: '3x', tabletWide: '2x' }}
        borderLeftWidth={showBorder ? 1 : 0}
        borderLeftStyle="solid"
        borderLeftColor={{
          _: 'transparent',
          tablet: getCountdownColor(countdownVariant, 'text'),
        }}
      >
        <Box
          as="p"
          mr="2x"
          variant="text.buttonTitle"
          color={getCountdownColor(countdownVariant, 'text')}
          fontSize="xxs"
          dangerouslySetInnerHTML={{
            __html: countdownMessage,
          }}
        />
        <Countdown
          date={endAt}
          renderer={(props) => renderer(props, t, countdownVariant)}
          onComplete={onComplete}
        />
      </Flex>
    </Box>
  )
}

export default OfferCountDown
