import React from 'react'
import { Box, Image, Flex } from '@chatterbug/aaron'
import { useLocale } from 'src/lib/I18nContext'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'

import BannerSkeleton from '../BannerSkeleton'

export type Props = {
  message: string
  buttonLabel: string
  buttonLink: string
  imgUrl?: string
}

const BannerInfo: React.FC<Props> = ({
  message,
  buttonLabel,
  buttonLink,
  imgUrl,
}) => {
  const locale = useLocale()

  return (
    <BannerSkeleton
      backgroundColor="blue42"
      contentSection={
        <Flex alignItems="center" justifyContent="center">
          {imgUrl && (
            <Image
              src={imgUrl}
              alt=""
              width="48px"
              display={{ _: 'none', tablet: 'inline-block' }}
              mr="2x"
            />
          )}
          <Box
            as="p"
            variant="text.paragraph"
            color="white100"
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          />
        </Flex>
      }
      buttonSection={
        <Button
          href={buttonLink}
          label={buttonLabel}
          variant={BUTTON_VARIANTS.neutralOnDark}
          className="cta-button-info-banner"
          width={{
            _: '100%',
            tablet: 'auto',
          }}
          data-l1={locale}
        />
      }
    />
  )
}

export default BannerInfo
