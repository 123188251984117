import React, { useContext } from 'react'
import { SiteLocale } from 'src/types'

const I18nContext = React.createContext<SiteLocale>(SiteLocale.en)

export function useLocale(): SiteLocale {
  return useContext<SiteLocale>(I18nContext)
}

export default I18nContext
