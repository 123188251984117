/* It's a JS file so it can be executed outside of the app via node commands */
/* eslint-disable  @typescript-eslint/no-var-requires */
const enlocale = require('../../cms/language_pairs/en.json')
const delocale = require('../../cms/language_pairs/de.json')
const eslocale = require('../../cms/language_pairs/es.json')

const SUPPORTED_LOCALES = {
  en: enlocale,
  de: delocale,
  es: eslocale,
}

function getDefaultLocaleCode() {
  return 'en'
}

function getEnabledLocaleCodes() {
  return Object.keys(SUPPORTED_LOCALES)
}

function getLocaleDisplayName(locale) {
  return SUPPORTED_LOCALES[locale]
    ? SUPPORTED_LOCALES[locale].displayName
    : locale.toUpperCase()
}

function getLocaleLearningLanguages(locale) {
  return SUPPORTED_LOCALES[locale] ? SUPPORTED_LOCALES[locale].l2Codes : []
}

function getNativeLanguagesForLearningLanguage(l2Code) {
  return getEnabledLocaleCodes().reduce((l1Codes, locale) => {
    if (
      SUPPORTED_LOCALES[locale].l2Codes.some((item) => item.l2Code === l2Code)
    ) {
      l1Codes.push(locale)
    }

    return l1Codes
  }, [])
}

function correctPathIfNecessary(path) {
  let pathWithReplacedSlashes = path.replace(/\/{2,}/g, '/')
  if (
    !pathWithReplacedSlashes.startsWith('/') &&
    !pathWithReplacedSlashes.startsWith('http')
  ) {
    pathWithReplacedSlashes = `/${pathWithReplacedSlashes}`
  }
  if (
    !pathWithReplacedSlashes.endsWith('/') &&
    !(
      pathWithReplacedSlashes.includes('#') ||
      pathWithReplacedSlashes.includes('?')
    )
  ) {
    // otherwise Netlify will do a redirect to `<path>/` anyway
    pathWithReplacedSlashes = `${pathWithReplacedSlashes}/`
  }
  return pathWithReplacedSlashes
}

function getLocalizedPath(locale, canonicalPath) {
  if (canonicalPath.startsWith('http')) {
    return canonicalPath
  }
  return correctPathIfNecessary(`/${locale}${canonicalPath}`)
}

module.exports = {
  correctPathIfNecessary,
  getLocalizedPath,
  getEnabledLocaleCodes,
  getDefaultLocaleCode,
  getLocaleDisplayName,
  getLocaleLearningLanguages,
  getNativeLanguagesForLearningLanguage,
}
