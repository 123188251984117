import React from 'react'
import { Box } from '@chatterbug/aaron'

export const PAGE_HORIZONTAL_PADDING = {
  _: '16px',
  mobile: '40px',
  tablet: '24px',
}
export const MAX_WIDTHS_FOR_BREAKPOINTS = {
  laptop: 1200,
}

const PageContainer: React.FC<React.ComponentProps<typeof Box>> = (props) => {
  return (
    <Box
      mx="auto"
      px={PAGE_HORIZONTAL_PADDING}
      maxWidth={MAX_WIDTHS_FOR_BREAKPOINTS}
      {...props}
    />
  )
}

export default PageContainer
