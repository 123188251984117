import React from 'react'
import { I18n } from 'react-polyglot'
import { SiteLocale } from 'src/types'

import I18nContext from './I18nContext'

export type PageContentProps = {
  locale: SiteLocale
  localeResources: {
    [key: string]: string
  }
}

type Props = {
  // passed down from createPages(...) in gatsby-node.js
  pageContext: PageContentProps
}

const I18nProvider: React.FC<Props> = ({ pageContext, children }) => {
  return (
    <I18n locale={pageContext.locale} messages={pageContext.localeResources}>
      <I18nContext.Provider value={pageContext.locale}>
        {children}
      </I18nContext.Provider>
    </I18n>
  )
}

export default I18nProvider
