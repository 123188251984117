import React from 'react'
import { Box, Flex, Image, Grid } from '@chatterbug/aaron'

export type Props = {
  salesMessage: string
  imgUrl?: string
}

const Offer: React.FC<Props> = ({ salesMessage, imgUrl }) => {
  return (
    <Grid
      alignItems="center"
      gridGap="2x"
      gridTemplateColumns={{ _: '1fr', tablet: 'auto 1fr' }}
      justifyItems={{ _: 'center', tablet: 'flex-start' }}
    >
      {imgUrl && (
        <Image
          src={imgUrl}
          alt=""
          width="48px"
          display={{ _: 'none', tablet: 'inline-block' }}
        />
      )}
      <Flex
        flexWrap="wrap"
        flexDirection={{ _: 'row', tablet: 'column', tabletWide: 'row' }}
        textAlign={{ tablet: 'left' }}
        mt={{ tabletWide: '-4px' }}
      >
        <Box
          as="p"
          mr="1x"
          variant="text.paragraph"
          color="white100"
          dangerouslySetInnerHTML={{ __html: salesMessage }}
        />
      </Flex>
    </Grid>
  )
}

export default Offer
