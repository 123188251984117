import { LocalizedData, SiteLocale, LanguageCode, PolyglotFn } from 'src/types'

export const getLocalizedLanguageLabel = (
  code: LanguageCode | SiteLocale,
  t: PolyglotFn
): string => {
  const LANGUAGES = {
    [LanguageCode.en]: t('English'),
    [LanguageCode.de]: t('German'),
    [LanguageCode.es]: t('Spanish'),
    [LanguageCode.fr]: t('French'),
  }

  return LANGUAGES[code] || code.toUpperCase()
}

export const mapToLocalizedLanguageLabels = (
  codes: LanguageCode[],
  t: PolyglotFn
): string[] => {
  return codes.map((code) => getLocalizedLanguageLabel(code, t))
}

export const getLocalizedText = (
  localizedList: LocalizedData[],
  locale: SiteLocale
): string => {
  if (localizedList.length === 0) {
    return ''
  }

  const localizedTestimonialItem = localizedList.find(
    (item) => item.locale === locale
  )

  if (localizedTestimonialItem) {
    return localizedTestimonialItem.text
  }

  const enTestimonialItem = localizedList.find(
    (item) => item.locale === SiteLocale.en
  )

  return enTestimonialItem?.text || localizedList[0].text || ''
}

export const getLocalizedCtaLink = (
  localizedList: LocalizedData[],
  locale: SiteLocale
): string => {
  if (localizedList.length === 0) {
    return ''
  }

  const localizedCTAItem = localizedList.find((item) => item.locale === locale)

  if (localizedCTAItem) {
    return localizedCTAItem.text
  }

  const enCTALink = localizedList.find((item) => item.locale === SiteLocale.en)

  return enCTALink?.text || localizedList[0].text || ''
}

export const getInitiallySelectedL2Code = (
  locale: SiteLocale
): LanguageCode => {
  return locale === SiteLocale.en ? LanguageCode.de : LanguageCode.en
}
