import React from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import 'normalize.css/normalize.css'

import theme from './theme'
import './theme.css'

const ThemeProvider: React.FC = ({ children }) => {
  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
}

export default ThemeProvider
