/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const WrapPageElement = require('./gatsby-wrap-page-element').default
const WrapRootElement = require('./gatsby-wrap-root-element').default

exports.wrapPageElement = WrapPageElement
exports.wrapRootElement = WrapRootElement
