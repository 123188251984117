import React, { useEffect, useState } from 'react'
import { calcTimeDelta } from 'react-countdown'
import { Flex, Grid } from '@chatterbug/aaron'
import Button, { FALLBACK_STYLES } from 'src/components/Button/Button'
import { useLocale } from 'src/lib/I18nContext'
import { getLessonsSignupUrl } from 'src/lib/app'

import BannerSkeleton from '../BannerSkeleton'
import Offer from './subcomponents/Offer'
import OfferCountDown from './subcomponents/OfferCountDown'

const BUTTON_NEUTRAL_YELLOW = {
  ...FALLBACK_STYLES,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'yellow50',
  color: 'yellow50',
  backgroundColor: 'transparent',
  ':hover, :active, :focus': {
    color: 'yellow45',
    borderColor: 'yellow45',
    backgroundColor: 'rgba(235, 174, 15, 0.1)',
  },
}

export type Props = {
  startAt: Date
  endAt: Date
  salesMessage: string
  offerText: string
  imgUrl?: string
  countdownMessage: string
  background?: string
  ctaLink?: string
}

const BannerOfferCountdown: React.FC<Props> = ({
  startAt,
  endAt,
  salesMessage,
  imgUrl,
  offerText,
  countdownMessage,
  background = null,
  ctaLink = '',
}) => {
  const locale = useLocale()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    // execute only in a browser, not during a Gatsby build
    if (typeof window !== 'undefined') {
      setVisible(
        calcTimeDelta(startAt)?.completed && !calcTimeDelta(endAt)?.completed
      )
    }
  }, [setVisible, startAt, endAt])

  if (!visible) {
    return null
  }

  if (!background) {
    background = 'linear-gradient(90.07deg, #302551 1.05%, #37296F 89.43%)'
  }

  return (
    <BannerSkeleton
      backgroundColor={background}
      breakpoint="tabletWide"
      contentSection={
        <Grid
          gridGap="2x"
          columns={{ _: '1fr', tablet: '1fr 1fr', tabletWide: 'auto auto' }}
          alignItems="center"
        >
          <Offer salesMessage={salesMessage} imgUrl={imgUrl} />
          <OfferCountDown
            countdownMessage={countdownMessage}
            endAt={endAt}
            onComplete={() => setVisible(false)}
            countdownVariant="default"
          />
        </Grid>
      }
      buttonSection={
        <Button
          href={ctaLink || getLessonsSignupUrl(locale)}
          label={<Flex alignItems="center">{offerText}</Flex>}
          variant={BUTTON_NEUTRAL_YELLOW}
          className="cta-banner-countdown-offer"
          width={{
            _: '100%',
            laptop: 'auto',
          }}
          data-l1={locale}
        />
      }
    />
  )
}

export default BannerOfferCountdown
