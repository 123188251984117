import React from 'react'
import BannerOfferCountdown from 'src/components/Banner/BannerOfferCountdown/BannerOfferCountdown'
import { PageContext } from 'src/types'
import { useStaticQuery, graphql } from 'gatsby'
import { getLocalizedText, getLocalizedCtaLink } from 'src/lib/localization'
import { useLocale } from 'src/lib/I18nContext'
import BannerInfo from 'src/components/Banner/BannerInfo/BannerInfo'
export type Props = {
  pageContext: PageContext
}

const GlobalBanner: React.FC<Props> = ({ pageContext }) => {
  const locale = useLocale()
  const data = useStaticQuery(graphql`
    query MyQuery {
      allBannersJson(filter: { isLive: { eq: true } }) {
        nodes {
          name
          startAt
          endAt
          localizedSalesMessage {
            locale
            text
          }
          localizedCtaLink {
            locale
            text
          }
          localizedCountdownMessage {
            locale
            text
          }
          localizedCtaLabel {
            locale
            text
          }
          image
          activeLocales
          type
          pagesToExcludeFrom
        }
      }
    }
  `)

  const banner = data?.allBannersJson?.nodes[0]

  // TODO 1: make the CTA link for BannerOfferCountdown variable
  // TODO 2: Refactor banners to have unified interface, only require start and end date for countdown (and later gifts).

  // Don't show for unsupported locale or on a page where it should be excluded.
  if (
    !banner ||
    !banner?.activeLocales.includes(locale) ||
    banner?.pagesToExcludeFrom?.some((path: string) =>
      pageContext.canonicalPath.includes(path)
    )
  ) {
    return null
  } else {
    if (banner?.type === 'info') {
      return (
        <BannerInfo
          message={getLocalizedText(banner.localizedSalesMessage, locale)}
          buttonLabel={getLocalizedText(banner.localizedCtaLabel, locale)}
          buttonLink={getLocalizedText(banner.localizedCtaLink, locale)}
          imgUrl={banner.image}
        />
      )
    } else if (banner.type === 'countdown') {
      return (
        <BannerOfferCountdown
          startAt={banner.startAt}
          endAt={banner.endAt}
          salesMessage={getLocalizedText(banner.localizedSalesMessage, locale)}
          countdownMessage={
            banner.localizedCountdownMessage &&
            getLocalizedText(banner.localizedCountdownMessage, locale)
          }
          offerText={getLocalizedText(banner.localizedCtaLabel, locale)}
          imgUrl={banner.image}
          ctaLink={getLocalizedCtaLink(banner.localizedCtaLink, locale)}
        />
      )
      // GIFTS ARE MISSING FOR NOW
    } else {
      return null
    }
  }
}

export default GlobalBanner
