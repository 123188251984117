import React from 'react'
import styled from 'styled-components'
import { Box } from '@chatterbug/aaron'

type Variant = {
  backgroundColor: string
  color: string
  [key: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export type Props = {
  type?: 'button' | 'submit'
  href: string | null
  label: any
  variant: Variant
  width?: string | number | { [key: string]: string }
  className?: string
  accessibleText?: string
  disabled?: boolean
  [key: string]: unknown
}

export const FALLBACK_STYLES = {
  border: 'none',
  ':disabled': {
    backgroundColor: 'gray88',
  },
  ':hover': {
    ':disabled': {
      backgroundColor: 'gray88',
      cursor: 'not-allowed',
    },
  },
}

export const BUTTON_VARIANTS: { [key: string]: Variant } = {
  primary: {
    ...FALLBACK_STYLES,
    backgroundColor: 'pink51',
    color: 'white100',
    ':hover, :active, :focus': {
      backgroundColor: 'pink30',
    },
  },
  secondary: {
    ...FALLBACK_STYLES,
    backgroundColor: 'blue42',
    color: 'white100',
    ':hover, :active, :focus': {
      backgroundColor: 'blue30',
    },
  },
  secondaryOutline: {
    ...FALLBACK_STYLES,
    backgroundColor: 'inherit',
    color: 'gray46',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray80',
  },
  secondaryOutlineHighlighted: {
    ...FALLBACK_STYLES,
    backgroundColor: 'inherit',
    color: 'blue42',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'blue42',
  },
  brand: {
    ...FALLBACK_STYLES,
    backgroundColor: 'yellow50',
    color: 'black19',
    ':hover, :active, :focus': {
      backgroundColor: 'yellow45',
    },
  },
  white: {
    ...FALLBACK_STYLES,
    backgroundColor: 'gray99',
    color: 'black19',
    ':hover, :active, :focus': {
      backgroundColor: 'gray88',
    },
  },
  whiteWithOutline: {
    ...FALLBACK_STYLES,
    backgroundColor: 'gray99',
    color: 'black19',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray80',
    ':hover, :active, :focus': {
      backgroundColor: 'gray88',
    },
  },
  neutral: {
    ...FALLBACK_STYLES,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray60',
    color: 'gray46',
    backgroundColor: 'transparent',
    ':hover, :active, :focus': {
      color: 'black0',
      borderColor: 'black0',
    },
  },
  neutralOnDark: {
    ...FALLBACK_STYLES,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'white100',
    color: 'white100',
    backgroundColor: 'transparent',
    ':hover, :active, :focus': {
      color: 'white100',
      borderColor: 'white100',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
}

BUTTON_VARIANTS.neutralBorderless = {
  ...BUTTON_VARIANTS.neutral,
  color: 'black19',
  borderWidth: 0,
}

const StyledButton = styled(Box)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  font-size: 12px;
  padding: 0 24px;
  border-radius: 40px;
  text-decoration: none;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  cursor: pointer;
  transition: border 0.3s ease, color 0.3s ease, background 0.3s ease;
  :hover,
  :focus,
  :active {
    text-decoration: none;
  }
`

const Button: React.FC<Props> = ({
  type = 'button',
  href,
  label,
  variant,
  width,
  className,
  accessibleText,
  disabled,
  ...other
}) => {
  const isButton = !href

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <StyledButton
      as={isButton ? 'button' : 'a'}
      type={isButton ? type : undefined}
      href={href || undefined}
      variant="text.buttonTitle"
      width={width}
      sx={variant || BUTTON_VARIANTS.neutral}
      className={className}
      aria-label={accessibleText}
      {...other}
      disabled={disabled}
    >
      {label}
    </StyledButton>
  )
}

export default Button
