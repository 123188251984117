// The `System UI Theme Specification` is a general purpose format for storing a
// design systems style and scales.
// https://system-ui.com/theme/ | https://styled-system.com/table

import space from '@chatterbug/theme/tokens/spacing'
import shadows from '@chatterbug/theme/tokens/shadows'
import mediaQueries from '@chatterbug/theme/tokens/mediaQueries'
import lineHeights from '@chatterbug/theme/tokens/lineHeights'
import borderWidths from '@chatterbug/theme/tokens/borderWidths'
import fontFamilies from '@chatterbug/theme/tokens/fontFamilies'
import fontWeights from '@chatterbug/theme/tokens/fontWeights'
import letterSpacings from '@chatterbug/theme/tokens/letterSpacings'
import radii from '@chatterbug/theme/tokens/radii'
import fontSizes from '@chatterbug/theme/tokens/fontSizes'
import colors from '@chatterbug/theme/tokens/colors'
import { useTheme as defaultUseTheme } from 'styled-components'

type Tokens = {
  [key: string]: string
}

const transformFontFamilies = (ff: Tokens) => {
  // need to add 'sans-serif' to exported token
  const transformedNames: Tokens = {}
  Object.keys(ff).forEach((fontFamily) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (transformedNames[fontFamily] = ff[fontFamily]
      .match(/[A-Z][a-z]+/g)
      .join('-')
      .toLowerCase())
  })
  return transformedNames
}

const FONT_FAMILIES = transformFontFamilies(fontFamilies as Tokens)

const newMediaQueries = {
  ...mediaQueries,
  mobileWide: '480px'
}

const BREAKPOINTS: string[] = []
Object.keys(newMediaQueries).forEach((mediaQuery) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  BREAKPOINTS[mediaQuery] = newMediaQueries[mediaQuery]
})

const DEFAULT_FONT_FAMILY = `${FONT_FAMILIES['body']}, sans-serif`

const TEXT_VARIANTS = {
  h1: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: fontWeights['regular'],
    fontSize: fontSizes['xxl'],
    color: colors['black0'],
  },
  h2: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: fontWeights['regular'],
    fontSize: fontSizes['xl'],
    color: colors['black0'],
  },
  h3: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: 600,
    fontSize: fontSizes['lg'],
    color: colors['black0'],
  },
  h4: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: 600,
    fontSize: fontSizes['md'],
    color: colors['black0'],
  },
  h5: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: 600,
    fontSize: fontSizes['sm'],
    lineHeight: lineHeights.sm,
    color: colors['black0'],
  },
  paragraph: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: fontWeights['regular'],
    fontSize: fontSizes['sm'],
    color: colors['black19'],
    lineHeight: lineHeights.lg,
  },
  paragraphSmall: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: fontWeights['regular'],
    fontSize: fontSizes['xxs'],
    color: colors['black19'],
    lineHeight: lineHeights.lg,
  },
  pageTitle: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: fontWeights['regular'],
    fontSize: fontSizes['lg'], // this is 28px, but Figma says it should be 26px. Do we need another fontSize
    textTransform: 'capitalize',
    color: colors['black0'],
  },
  buttonTitle: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: 600,
    fontSize: fontSizes['xs'],
    textTransform: 'uppercase',
    letterSpacing: '0.06em', // needs to be updated once letterSpacings are correct
    color: colors['black0'],
  },
  subtitle: {
    // other subtitle styles are taking precedence
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: fontWeights['regular'],
    fontSize: fontSizes['xs'],
    lineHeight: lineHeights.lg,
    color: colors['gray46'],
  },
  tinyTitle: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: 600,
    fontSize: fontSizes['xxs'],
    color: colors['black0'],
  },
  monospace: {
    fontFamily:
      "Consolas, Monaco, 'Liberation Mono', 'Courier New', Courier, monospace",
    fontSize: fontSizes['xs'],
    fontWeight: fontWeights['regular'],
    color: colors['gray46'],
  },
  link: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: fontWeights['semiBold'],
    fontSize: fontSizes['sm'],
    color: colors['blue42'],
    lineHeight: lineHeights.lg,
    textDecoration: 'underline',
    ':hover, :active, :focus': {
      color: colors['blue30'],
    },
  },
}

const ACCESSIBILITY_VARIANTS = {
  hidden: {
    border: '0',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: '0',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  },
}

const theme = {
  breakpoints: BREAKPOINTS,
  mediaQueries: newMediaQueries,
  space: {
    ...space,
  },
  radii: {
    ...radii,
    full: '100%',
  },
  shadows: {
    ...shadows,
    darkFocusRing: '0px 0px 0px 2px rgba(0, 0, 0, 1)',
  },
  lineHeights: {
    ...lineHeights,
  },
  borderWidths: {
    ...borderWidths,
  },
  fontWeights: {
    ...fontWeights,
    semiBold: 600,
  },
  fontSizes: {
    ...fontSizes,
  },
  letterSpacings: {
    ...letterSpacings,
    wide: '0.06em',
    tight: '-0.04em',
  },
  fonts: {
    heading: 'sofia-pro-soft',
    monospace:
      "Consolas, Monaco, 'Liberation Mono', 'Courier New', Courier, monospace",
    ...FONT_FAMILIES,
  },
  colors: {
    ...colors,
  },
  namedColors: {
    streamsBackground: 'rgba(250, 250, 250, 1)', // gray99
    neutralBackground: '#fafafa',
  },
  text: {
    ...TEXT_VARIANTS,
  },
  a11y: {
    ...ACCESSIBILITY_VARIANTS,
  },
}

export const useTheme: () => typeof theme = defaultUseTheme as any

export default theme
