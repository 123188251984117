import React from 'react'
import I18nProvider from './src/lib/I18nProvider'
import GlobalBanner from './src/sections/GlobalBanner/GlobalBanner'

const WrapPageElement = ({ element, props }) => {
  return (
    <I18nProvider {...props}>
      <GlobalBanner {...props} />
      {element}
    </I18nProvider>
  )
}

export default WrapPageElement
