export type CountdownVariant = 'light' | 'dark' | 'default'

export const getCountdownColor = (
  variant: CountdownVariant,
  textType: 'number' | 'text'
) => {
  if (variant === 'default') {
    return textType === 'number' ? 'yellow50' : 'gray95'
  }
  return variant === 'light' ? 'white100' : 'black0'
}
