import React from 'react'
import { Box, Grid } from '@chatterbug/aaron'
import PageContainer from 'src/components/PageContainer/PageContainer'

export type Props = {
  backgroundColor?: string
  backgroundSection?: any
  contentSection: any
  buttonSection: any
  breakpoint?: string // when should it change from one-column grid to two-columns grid?
}

const BannerSkeleton: React.FC<Props> = ({
  backgroundColor,
  backgroundSection,
  contentSection,
  buttonSection,
  breakpoint = 'tablet',
}) => {
  return (
    <Box
      as="section"
      className="cb-banner"
      sx={{
        position: 'relative',
        // add a background color if it was defined
        ...(backgroundColor
          ? {
              // a linear gradient works only for `background` or `backgroundImage` props
              // in order to use our color tokens we have to specify `backgroundColor` prop
              [backgroundColor.includes('gradient')
                ? 'background'
                : 'backgroundColor']: backgroundColor,
            }
          : {}),
      }}
    >
      {backgroundSection && (
        <Box
          sx={{ position: 'absolute', left: 0, width: '100%', height: '100%' }}
        >
          {backgroundSection}
        </Box>
      )}
      <PageContainer>
        <Grid
          position="relative"
          justifyContent="center"
          textAlign="center"
          alignItems={{
            _: 'stretch',
            [breakpoint]: 'center',
          }}
          gridGap="3x"
          columns={{
            _: '1fr',
            [breakpoint]: 'auto auto',
          }}
          minHeight={{
            [breakpoint]: 56,
          }}
          py={{
            _: '2x',
            [breakpoint]: '05x',
          }}
        >
          {contentSection}
          {buttonSection}
        </Grid>
      </PageContainer>
    </Box>
  )
}

export default BannerSkeleton
