import React from 'react'
import { Box, Flex } from '@chatterbug/aaron'

import { CountdownVariant, getCountdownColor } from '../utils'

export type Props = {
  label: string
  value: string | number
  index: number
  countdownVariant: CountdownVariant
}

const CountDownItem: React.FC<Props> = ({
  label,
  value,
  index,
  countdownVariant,
}) => {
  const isFirst = index === 0

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      borderLeftWidth={1}
      borderLeftStyle="solid"
      borderLeftColor={isFirst ? 'transparent' : 'gray80'}
      ml={isFirst ? 0 : '2x'}
      pl={isFirst ? 0 : '2x'}
    >
      <Box
        variant="text.h4"
        color={getCountdownColor(countdownVariant, 'number')}
      >
        {value}
      </Box>
      <Box
        variant="text.buttonTitle"
        color={getCountdownColor(countdownVariant, 'text')}
        fontSize="xxs"
      >
        {label}
      </Box>
    </Flex>
  )
}

export default CountDownItem
